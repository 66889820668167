import navItems from "./navItems"

export default {
  slug: navItems[1].children[0].to,
  url: "/caf-standard/quality-tested",
  title: navItems[1].children[0].label,
  content: {
    section2: {
      h1: {
        head: "",
        tail: "Quality Tested.",
      },
      ul: [
        `Every individual component used in manufacturing is tested to the highest degree to identify the very best product and supplier.`,
        `The completed product is tested to verify performance to design criteria and validate manufacturing processes and procedures.`,
        `Ongoing quality control ensures all components / products meet established Clean Air Filter standards.`,
        `Collection of data establishes baseline.`,
      ],
    },
    sectionCafStandard: {
      h2: `Clean Air Filter Standard`,
      p1: `You place your trust in our products to protect you
from harm. That is a responsibility we do not take
lightly. We honor your trust through our
commitment to going beyond what is expected
and a relentless devotion to doing more than
required. Because what’s acceptable to most is
simply not good enough for us. We test ourselves
and our products to a higher standard and refuse
to settle for anything less than exceptional.`,
      p2: `
Every one of our products is built right, every time.
And through a constant cycle of rigorous,
methodical, and painstaking testing and
evaluation, we reinforce our dedication to quality
because that’s what keeps our customers safe,
healthy, and satisfied year after year.`,
    },
    section1: {
      h2: `Advanced Testing Facility`,
      p0: `Every one of our products is built right, every time.
And through a constant cycle of rigorous,
methodical, and painstaking testing and
evaluation, we reinforce our dedication to quality
because that’s what keeps our customers safe,
healthy, and satisfied year after year.`,
      p1:
        "Built in 1994, Clean Air Filter’s dedicated air quality lab is where each product undergoes rigorous testing to ensure that customers receive high-quality air filters and air filtration protection systems.",
      a: "CAF48R Certificate",
      p2:
        "Clean Air Filter has been involved with several Original Equipment Manufacturers (OEM) in the development of filtration for new model cabs.",
    },
  },
}