import React from "react";
import Layout from "../../components/layout";
import data from '../../data/the-lab';
import { Helmet } from 'react-helmet';
import CAF48R from "../resources/CERTIFICATE OF COMPLIANCE CAF48R-4.pdf"
export default () => {
    const { title, content } = data
    const { section1, section2, sectionCafStandard } = content
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`${title} | Clean Air Filter`}</title>
        </Helmet>
        <section>
          <article className="w-60">
            <h1>
              <span className="tail">
                <span className="head">{section2.h1.head}</span>{" "}
                {section2.h1.tail}
              </span>
            </h1>
            <p>{section1.p0}</p>
            {/* <p>{section1.p0}</p> */}
            <ul>
              {section2.ul.map((item, index) => (
                <li key={index}>
                  <p>{item}</p>
                </li>
              ))}
            </ul>
          </article>
        </section>
        {/* <section>
          <h2>{sectionCafStandard.h2}</h2>
          <article className="w-60">
            <p>{sectionCafStandard.p1}</p>
            <p>{sectionCafStandard.p2}</p>
          </article>
        </section> */}
        <section>
          <h2>{section1.h2}</h2>
          <article className="w-60">
            <p>{section1.p1}</p>
            <p>
              <a
                className="download-link"
                target={"_blank"}
                rel="noreferrer"
                href={CAF48R}
              >
                {section1.a}
              </a>
            </p>
            <p>{section1.p2}</p>
          </article>
        </section>
      </Layout>
    )
}